<template>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          :md="showHelp ? 8 : 11"
          :sm="12"
          v-show="!showExpand"
          class="pr-0 pt-5 pb-0"
        >
          <PageHeader :title="title" />
          <v-row>
            <v-col
              cols="12"
              :md="showHelp ? 12 : 6"
              sm="3"
              class="pr-0"
              v-for="item in views"
              :key="item.id"
            >
              <v-card
                color="primary"
                dark
                @click="whereToPush(item)"
                height="100px"
              >
                <v-card-title class="text-h5">
                  {{ item.title }}
                </v-card-title>
  
                <v-card-subtitle>{{ item.subtitle }} </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!-- Texto de ayuda principal -->
        <v-col
          cols="12"
          :md="!showHelp ? 1 : showExpand ? 12 : 4"
          class="text-right pt-3 pr-0"
          v-if="showIcon"
        >
          <Ayuda
            :optionCode="optionCode"
            :pantallaTitle="title"
            @toggleExpand="showExpand = !showExpand"
            @toggleHelp="toggleHelp"
            @toggleIcon="toggleIcon()"
          />
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import PageHeader from "@/components/ui/PageHeader";
  import enums from "@/utils/enums/index.js";
  import Ayuda from "@/components/shared/Ayuda.vue";
  
  export default {
    name: "FacturacionPrestaciones",
    components: { PageHeader, Ayuda },
    async created() {
      await this.$store.dispatch(
        "user/updateFrequencyByOptionCode", //aparece como página visitada si ingreso por url
        this.optionCode
      );
      this.setPermisos();
      this.views.sort(function(a, b) {
        return a.order - b.order;
      });
    },
    data() {
      return {
        title: "Facturación de prestaciones",
        allowedActions: null,
        optionCode: enums.webSiteOptions.FACTURACION_PRESTACIONES,
        views: [],
        showExpand: false,
        showHelp: false,
        showIcon: true
      };
    },
    methods: {
      async setPermisos() {
        this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
        this.allowedActions?.forEach(x => {
          switch (x.optionCode) {
            case enums.webSiteOptions.PORCENTAJES_VENTA_OS:
              this.views.push({
                id: 1,
                title: x.title,
                order: 1,
                subtitle: "Permite configurar el % que se aplica al precio liquidado al prestador",
                path: "PorcentajesVentaxOS"
              });
              break;
         
          }
        });
      },
      whereToPush(item) {
        if (item.id < 0) {
          this.$router.push({
            name: item.path,
            params: { url: encodeURIComponent(item.params) }
          });
        } else {
          this.$store.dispatch("user/changeAppLoading", {
            status: true,
            text: "",
            opaque: true
          });
          this.$router.push({
            name: item.path
          });
        }
      },
      toggleHelp() {
        this.showHelp = !this.showHelp;
        this.showHelp ? [] : (this.showExpand = false);
      },
      toggleIcon() {
        this.showIcon = true;
      }
    }
  };
  </script>
  
  <style scoped>
  ::v-deep .text-h5 {
    font-size: 1.35rem !important;
    font-weight: 450;
    line-height: 2rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif !important;
  }
  </style>
  